// @store/modules/search.js

export default {
  namespaced: true,
  state: {
    searchCriteria: {
      occupations: [],
      industries: [],
      locations: [],
      videoTypes: [],
    },
  },
  mutations: {
    SET_SEARCH_CRITERIA(state, criteria) {
      state.searchCriteria = criteria;
    },
  },
  actions: {
    setSearchCriteria({ commit }, criteria) {
      commit('SET_SEARCH_CRITERIA', criteria);
    },
  },
  getters: {
    getSearchCriteria: (state) => state.searchCriteria,
  },
};
