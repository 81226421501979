<template>
  <footer :class="footerClass">
    <div id="footer" class="bg-gray-900 text-white">
      <div class="container mx-auto px-4 py-8">
        <div class="flex items-center justify-between">
          <router-link to="/" class="flex items-center">
            <img
              src="/images/logo.png"
              alt="Smart Video Recruitment"
              class="h-auto w-24"
            />
          </router-link>

          <ul class="flex space-x-4">
            <li>
              <router-link to="/" class="text-white"> Home </router-link>
            </li>
            <li>
              <router-link to="/search" class="text-white">
                Search actors
              </router-link>
            </li>
            <li>
              <router-link to="/about" class="text-white"> About </router-link>
            </li>
            <li>
              <router-link to="/profile" class="text-white">
                Profile
              </router-link>
            </li>
          </ul>

          <div class="text-white">
            <h2 class="text-lg font-semibold">Follow us</h2>
            <ul class="mt-2 flex space-x-4">
              <li>
                <a href="#" class="text-blue-400"
                  ><i class="fab fa-twitter"></i
                ></a>
              </li>
              <li>
                <a href="#" class="text-blue-600"
                  ><i class="fab fa-facebook"></i
                ></a>
              </li>
              <li>
                <a href="#" class="text-blue-700"
                  ><i class="fab fa-linkedin"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
        <p
          class="text-center text-sm leading-6 text-slate-500 dark:text-gray-300"
        >
          &copy; {{ theYear }} {{ company }} &nbsp;&nbsp;|&nbsp;&nbsp;
          <router-link to="/terms-and-conditions" class="text-white underline">
            Terms &amp; Conditions
          </router-link>
        </p>
        <a href="https://stevenhardy.digital" class="hidden" target="_blank"
          >Steven Hardy Digital</a
        >

        <div class="mt-8 flex space-x-4">
          <div class="flex-1 rounded bg-blue-600 py-4 text-center">
            <h3>
              Facebook
              <span class="text-white"><i class="fab fa-facebook"></i></span>
            </h3>
          </div>
          <div class="flex-1 rounded bg-blue-400 py-4 text-center">
            <h3>
              Twitter
              <span class="text-white"><i class="fab fa-twitter"></i></span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
  components: {},
  props: {
    footerClass: {
      type: String,
      default: 'mx-auto w-full',
    },
    borderClass: {
      type: String,
      default: 'border-slate-900/5 dark:border-slate-700',
    },
  },
  setup() {
    return {};
  },
  data() {
    return {
      company: 'Smart Video Recruitment',
    };
  },
  computed: {
    theYear() {
      const startYear = '2023';
      const currentYear = new Date().getFullYear();
      if (currentYear == startYear) {
        return currentYear;
      }
      return `${startYear}-${currentYear}`;
    },
  },
  watch: {},
  created() {},
  mounted() {},
  beforeUnmount() {},
  updated() {},
  methods: {},
};
</script>

<style scoped></style>

<style lang="scss" scoped></style>
